// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ubWGFej0J", "Sy2TAwhrn"];

const serializationHash = "framer-3ZS5c"

const variantClassNames = {Sy2TAwhrn: "framer-v-1s63ws2", ubWGFej0J: "framer-v-1by0r11"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Closed: "ubWGFej0J", Open: "Sy2TAwhrn"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ubWGFej0J"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ubWGFej0J", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1by0r11", className, classNames)} data-framer-name={"Closed"} initial={variant} layoutDependency={layoutDependency} layoutId={"ubWGFej0J"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Sy2TAwhrn: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-vyeuib"} data-framer-name={"Frame"} layoutDependency={layoutDependency} layoutId={"RXDniPV1W"} style={{rotate: 0}} variants={{Sy2TAwhrn: {rotate: -180}}}><SVG className={"framer-1t2n52x"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"ThRXrl7LN"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-a73c33e8-d973-47a3-8eb6-83b3878359ee, rgb(17, 20, 6)) /* {&quot;name&quot;:&quot;Greenscale/Black&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={1572246220} withExternalLayout/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-3ZS5c[data-border=\"true\"]::after, .framer-3ZS5c [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-3ZS5c.framer-1g64y2k, .framer-3ZS5c .framer-1g64y2k { display: block; }", ".framer-3ZS5c.framer-1by0r11 { height: 14px; overflow: visible; position: relative; width: 14px; }", ".framer-3ZS5c .framer-vyeuib { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-3ZS5c .framer-1t2n52x { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Sy2TAwhrn":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerg8KwovKFU: React.ComponentType<Props> = withCSS(Component, css, "framer-3ZS5c") as typeof Component;
export default Framerg8KwovKFU;

Framerg8KwovKFU.displayName = "Caret";

Framerg8KwovKFU.defaultProps = {height: 14, width: 14};

addPropertyControls(Framerg8KwovKFU, {variant: {options: ["ubWGFej0J", "Sy2TAwhrn"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerg8KwovKFU, [])